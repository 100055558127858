import React, { useEffect, useState } from "react";
import logo from "./assets/cguys-logo.svg";
import cguys from "./assets/cguys-icon.png";
import { useAddress } from "@thirdweb-dev/react";
import axios from "axios";
import { ethers } from "ethers";
import { abi, signer } from "./abiConracts/vesting";
import { provider, abiERC20 } from "./abiConracts/erc20";
import {
  Box,
  Container,
  Heading,
  HStack,
  VStack,
  Text,
  Image,
  useToast,
} from "@chakra-ui/react";

const Vesting = () => {
  const toast = useToast();
  const address = useAddress();
  const qs = require("quickswap-sdk");

  // const [contractAddress, setContractAddress] = useState("");
  const [contract, setContract] = useState(null);
  const [datiVesting, setDatiVesting] = useState({});
  const [tokens, setTokens] = useState({});
  const [released, setReleased] = useState({});
  const [seerPrice, setSeerPrice] = useState(0);
  const [now, setNow] = useState(Date.now());

  // const tokenIn = "0xe11A86849d99F524cAC3E7A0Ec1241828e332C62"; // USDC MUMBAI
  // const tokenOut = "0x1e6E33341b87874d42D4D681ba5A5301423cF9DB"; //  CGUY MUMBAI

  const tokenIn = "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174"; // USDC POLYGON
  const tokenOut = "0x456EF6FA075C6994088EcF9f9917a2634C18E3cf"; //  SEER POLYGON

  const icons = {
    tokenIn:
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
    tokenOut: cguys,
  };

  const getContractAddress = async () => {
    const res = await axios.get(
      "https://backend.roundtable.vision/api/vesting/" + address
    );
    const { data } = await res;
    // setContractAddress(data["address"]);

    return new ethers.Contract(data["address"], abi, signer);
  };

  const getTokenMeta = async (_token, contract) => {
    const erc20 = new ethers.Contract(_token, abiERC20, signer);
    const name_ = await erc20.name();
    const symbol_ = await erc20.symbol();
    const decimals_ = await erc20.decimals();
    const balance_ = await erc20.balanceOf(contract.address);
    const quickSwapInfo_ = await qs.Fetcher.fetchTokenData(
      137,
      _token,
      provider
    );
    return {
      name: name_,
      symbol: symbol_,
      decimals: decimals_,
      balance: balance_ / 10 ** decimals_,
      quickSwapInfo: quickSwapInfo_,
    };
  };

  const getTokensData = async (contract) => {
    const _tokenIn = await getTokenMeta(tokenIn, contract);
    const _tokenOut = await getTokenMeta(tokenOut, contract);
    return { [tokenIn]: _tokenIn, [tokenOut]: _tokenOut };
  };

  const getDatiVesting = async (contract) => {
    return {
      beneficiary: await contract.beneficiary(),
      duration: await contract.duration(),
      start: await contract.start(),
    };
  };

  const getReleased = async (contract) => {
    const _tokenIn = await contract["released(address)"](tokenIn);
    const _tokenOut = await contract["released(address)"](tokenOut);
    setReleased({ [tokenIn]: _tokenIn, [tokenOut]: _tokenOut });
  };

  const setPriceSeer = async (meta) => {
    const pair = await qs.Fetcher.fetchPairData(
      meta[tokenIn].quickSwapInfo,
      meta[tokenOut].quickSwapInfo,
      provider
    );
    const route = new qs.Route([pair], meta[tokenOut].quickSwapInfo);
    // console.log(route.midPrice.toSignificant(6));
    setSeerPrice(route.midPrice.toFixed(2));
  };

  useEffect(() => {
    getContractAddress().then((contract) => {
      setContract(contract);
      getDatiVesting(contract).then((dati) => {
        setDatiVesting(dati);
      });
      getTokensData(contract).then((meta) => {
        setTokens(meta);
        setPriceSeer(meta);
      });
      getReleased(contract);
    });
  }, [address, released]);

  const release = async () => {
    let tx = await contract["release(address)"](tokenOut);
    await tx.wait();
    toast({
      title: "Release token",
      description: `Sono stati reclamati dei token`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };

  return (
    <Container maxW="100%" p="0" color="#ffffff" className="vesting-contract">
      <VStack h="100vh">
        <HStack w="100%" h="202px" mr="10px">
          <Box w="50%">
            <Box mx="140px" className="logo">
              <img src={logo} alt="CryptoGuys" />
            </Box>
          </Box>
          <Box w="50%" className="beneficiary">
            <p>Beneficiary</p>
            <p className="address">{address}</p>
          </Box>
        </HStack>

        <HStack w="100%" className="contract-details">
          <Box w="50%">
            <HStack mx="140px">
              {datiVesting.start ? (
                <Box w="50%">
                  <Heading as="h2">
                    <span>Start date</span>
                  </Heading>
                  <Text>
                    {new Date(datiVesting.start * 1000).getFullYear() +
                      "/" +
                      (
                        new Date(datiVesting.start * 1000).getMonth() +
                        1 +
                        ""
                      ).padStart(2, "0") +
                      "/" +
                      (
                        new Date(datiVesting.start * 1000).getDate() + ""
                      ).padStart(2, "0")}
                  </Text>
                  <Text>
                    {(
                      new Date(datiVesting.start * 1000).getHours() + ""
                    ).padStart(2, "0") +
                      ":" +
                      (
                        new Date(datiVesting.start * 1000).getMinutes() + ""
                      ).padStart(2, "0") +
                      ":" +
                      (
                        new Date(datiVesting.start * 1000).getSeconds() + ""
                      ).padStart(2, "0")}
                  </Text>
                </Box>
              ) : null}
              {datiVesting.start && datiVesting.duration ? (
                <Box w="50%">
                  <Heading as="h2">
                    <span>End date</span>
                  </Heading>
                  <Text>
                    {new Date(
                      datiVesting.start.add(datiVesting.duration) * 1000
                    ).getFullYear() +
                      "/" +
                      (
                        new Date(
                          datiVesting.start.add(datiVesting.duration) * 1000
                        ).getMonth() +
                        1 +
                        ""
                      ).padStart(2, "0") +
                      "/" +
                      (
                        new Date(
                          datiVesting.start.add(datiVesting.duration) * 1000
                        ).getDate() + ""
                      ).padStart(2, "0")}
                  </Text>
                  <Text>
                    {(
                      new Date(
                        datiVesting.start.add(datiVesting.duration) * 1000
                      ).getHours() + ""
                    ).padStart(2, "0") +
                      ":" +
                      (
                        new Date(
                          datiVesting.start.add(datiVesting.duration) * 1000
                        ).getMinutes() + ""
                      ).padStart(2, "0") +
                      ":" +
                      (
                        new Date(
                          datiVesting.start.add(datiVesting.duration) * 1000
                        ).getSeconds() + ""
                      ).padStart(2, "0")}
                  </Text>
                </Box>
              ) : null}
            </HStack>
          </Box>
          {tokens[tokenOut] && seerPrice ? (
            <Box w="50%">
              <Heading as="h2">
                <span>{tokens[tokenOut].symbol} price</span>
              </Heading>
              {seerPrice ? <Text>$ {seerPrice}</Text> : null}
              <Text>&nbsp;</Text>
            </Box>
          ) : (
            ""
          )}
        </HStack>

        {tokens[tokenIn] && tokens[tokenIn] && released && seerPrice ? (
          <HStack w="100%">
            <Box w="50%" h="500px">
              <VStack mx="140px" mt="90px" className="tokens-panel">
                <Box w="100%">
                  <Heading as="h1">Vested Capital</Heading>
                  <Text className="price">
                    ${" "}
                    {(
                      tokens[tokenIn].balance +
                      tokens[tokenOut].balance * seerPrice +
                      (released[tokenOut] / 10 ** tokens[tokenOut].decimals) *
                        seerPrice
                    ).toFixed(2)}
                  </Text>
                </Box>
                <Box w="100%">
                  <HStack mt="20px">
                    <Image
                      borderRadius="full"
                      boxSize="30px"
                      src={icons.tokenIn}
                      alt={tokens[tokenIn].symbol}
                    />
                    <VStack w="100%">
                      <Heading as="h2">
                        <span>{tokens[tokenIn].symbol}</span>
                      </Heading>
                      <Text w="100%" className="price">
                        $ {tokens[tokenIn].balance.toFixed(2)}
                      </Text>
                    </VStack>
                  </HStack>
                  <>
                    <HStack mt="20px">
                      <Image
                        borderRadius="full"
                        boxSize="30px"
                        src={icons.tokenOut}
                        alt={tokens[tokenOut].symbol}
                      />
                      <VStack w="100%">
                        <Heading as="h2">
                          <span>{tokens[tokenOut].symbol} vested</span>
                        </Heading>
                        <Text w="100%" className="price">
                          $ {(tokens[tokenOut].balance * seerPrice).toFixed(2)}
                        </Text>
                      </VStack>
                    </HStack>
                    <HStack mt="20px">
                      <Image
                        borderRadius="full"
                        boxSize="30px"
                        src={icons.tokenOut}
                        alt={tokens[tokenOut].symbol}
                      />
                      <VStack w="100%">
                        <Heading as="h2">
                          <span>{tokens[tokenOut].symbol} released</span>
                        </Heading>
                        <Text w="100%" className="price">
                          ${" "}
                          {(
                            (released[tokenOut] /
                              10 ** tokens[tokenOut].decimals) *
                            seerPrice
                          ).toFixed(2)}
                        </Text>
                      </VStack>
                    </HStack>
                  </>
                </Box>
              </VStack>
            </Box>
            <Box w="50%" h="500px">
              <VStack mx="140px" mt="90px" className="cguy-panel">
                <Box w="100%">
                  <Text className="token-released">
                    {(
                      released[tokenOut] /
                      10 ** tokens[tokenOut].decimals
                    ).toFixed(2)}
                  </Text>

                  <Heading as="h2">
                    <span>{tokens[tokenOut].symbol}</span>
                  </Heading>
                  <Text>today</Text>
                  {datiVesting &&
                  datiVesting.start &&
                  now > datiVesting.start * 1000 ? (
                    <>
                      <button className="release-button" onClick={release}>
                        Transfer {tokens[tokenOut].symbol} to your wallet
                      </button>
                    </>
                  ) : (
                    <button
                      disabled="disabled"
                      className="release-button disabled"
                    >
                      Transfer {tokens[tokenOut].symbol} to your wallet
                    </button>
                  )}
                </Box>
              </VStack>
            </Box>
          </HStack>
        ) : null}

        <HStack w="100%" className="footer">
          {/*<Box w="50%">*/}
          {/*  <Heading mx="140px" as="h3">*/}
          {/*    Vesting period*/}
          {/*  </Heading>*/}
          {/*</Box>*/}
          <Box w="50%"></Box>
        </HStack>
      </VStack>
    </Container>
  );
};

export default Vesting;
