import React from "react";
import "./scss/main.scss";
import logo from "./assets/cguys-logo.svg";
import {
  ChainId,
  useAddress,
  useMetamask,
  useNetwork,
  useNetworkMismatch,
} from "@thirdweb-dev/react";
import { Center } from "@chakra-ui/react";
import Vesting from "./Vesting";

const App = () => {
  const desiredChain = ChainId.Polygon;
  const address = useAddress();
  const connectWithMetamask = useMetamask();
  const [, switchNetwork] = useNetwork();
  const isMismatched = useNetworkMismatch();

  return (
    <div className="App">
      {address && !isMismatched ? (
        <Vesting />
      ) : (
        <div className="login-page">
          <div>
            <img src={logo} alt="CryptoGuys" />
          </div>
          {address ? (
            <Center>
              <button
                className="login-button"
                onClick={() => switchNetwork(desiredChain)}
              >
                Switch Network
              </button>
            </Center>
          ) : (
            <Center>
              <button
                className="login-button"
                onClick={() => connectWithMetamask()}
              >
                Connect Wallet
              </button>
            </Center>
          )}
        </div>
      )}
    </div>
  );
};

export default App;
